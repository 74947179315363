import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo.js"
import Safe from "react-safe"

const AboutPage = () => (
  <Layout>
    <SEO title="Subscribe" />

    <div className={"page-header"}>
      <h2>Sign Up</h2>
    </div>

    <div className={"container"}>
      <div className={"targets"}>
        {/* <div className={"title"}>
                    <h2>Our Targets</h2>
                </div> */}

        <div className={"row"}>
          <div className={"col-12"}>
            <div className={"item"}>
              {/* <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css" /> */}

              <div id="mc_embed_signup">
                <form
                  action={process.env.REACT_APP_MAILCHIMP_URL}
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  novalidate
                >
                  <div id="mc_embed_signup_scroll">
                    <h5>Sign up to get notified about our upcoming beta!</h5>
                    <div className="indicates-required">
                      <span className="asterisk">*</span> indicates required
                    </div>
                    <div className="mc-field-group">
                      <label for="mce-FNAME">
                        First Name <span className="asterisk">*</span>
                      </label>
                      <input
                        type="text"
                        value=""
                        name="FNAME"
                        className="required"
                        id="mce-FNAME"
                      />
                    </div>
                    <div className="mc-field-group">
                      <label for="mce-EMAIL">
                        Email Address <span className="asterisk">*</span>
                      </label>
                      <input
                        type="email"
                        value=""
                        name="EMAIL"
                        className="required email"
                        id="mce-EMAIL"
                      />
                    </div>
                    <div
                      id="mergeRow-gdpr"
                      className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
                    >
                      <div className="content__gdpr">
                        <fieldset
                          className="mc_fieldset gdprRequired mc-field-group"
                          name="interestgroup_field"
                        >
                          <label className="checkbox subfield" for="gdpr_25">
                            <span>
                              {" "}
                              <input
                                type="checkbox"
                                id="gdpr_25"
                                name="gdpr[25]"
                                value="Y"
                                className="av-checkbox gdpr"
                              />
                              Yes, I consent to using my details to notify me
                              about the upcoming beta website launch.
                            </span>{" "}
                          </label>
                        </fieldset>
                        <p>
                          You can unsubscribe at any time by clicking the link
                          in the footer of our emails.
                        </p>
                      </div>
                      <div className="content__gdprLegal">
                        <p>
                          * We don’t share your personal info with anyone. For
                          information about our privacy practices, see our{" "}
                          <a href={process.env.REACT_APP_PRIVACY_URL}>
                            Privacy Policy
                          </a>
                          . We use Mailchimp as our marketing platform. By
                          clicking below to subscribe, you acknowledge that your
                          information will be transferred to Mailchimp for
                          processing.{" "}
                          <a
                            href="https://mailchimp.com/legal/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn more about Mailchimp's privacy practices here.
                          </a>
                        </p>
                      </div>
                    </div>
                    {/* <div id="mce-responses" className="clear">
		<div className="response" id="mce-error-response" style="display:none"></div>
		<div className="response" id="mce-success-response" style="display:none"></div>
	</div>    */}

                    <div aria-hidden="true" className="d-none">
                      <input
                        type="text"
                        name="b_a959898c9523af82e2845802e_3d5de989a8"
                        tabindex="-1"
                        value=""
                      />
                    </div>
                    <div className="clear">
                      <input
                        type="submit"
                        value="Submit"
                        name="subscribe"
                        id="mc-embedded-subscribe2"
                        className="btn btn-primary "
                      />
                    </div>
                  </div>
                </form>
              </div>

              <style />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutPage
